<template>
    <div class="custom-chart-tabs">
        <el-tabs class="demo-tabs" v-model="activeTab">
            <el-tab-pane :name="item.tabName" v-for="(item, index) in tabData" :key="index">
                <div class="w-100 d-flex overflow-x-auto">
                    <CircleChart
                        v-for="(chart, chartIdx) in item.data"
                        :key="chartIdx"
                        :labelBottom="chart.titleBottom"
                        :chartData="chart"
                        :chartConfigs="{
                            width: 260,
                            height: 0.8 * 230,
                            valueVisible: false
                         }"
                        :values="[chart.score, chart.importance]"
                        class="chart-item"
                        :class="{'isLoading': this.isLoading === chart.titleBottom}"
                        @click="onClickProductAreas(chart)"
                    />
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";

export default {
    name: "CustomChartTabs",
    components: {
        CircleChart
    },
    props: {
        activeTab: String,
        tabData: Array
    },
    data() {
        return {
            isLoading: '',
        }
    },
    methods: {
        onClickProductAreas(chart) {
            if (this.isLoading) return;
            this.isLoading = chart.titleBottom;
            this.$emit('onClickProductAreas', chart.titleBottom);
        }
    }
}
</script>

<style lang="scss">
.custom-chart-tabs {
    .el-tabs {
        &__header {
            display: none;
        }
    }

    #chart {
        .label {
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            text-align: center;
            color: #515151;
        }
    }
    .chart-item {
        cursor: pointer;
        &:hover {
            background: #fafafa;
            border-radius: 20px;
        }
        &.isLoading {
            opacity: 0.5;
        }
    }
}

.overflow-x-auto {
    overflow-y: hidden;
    overflow-x: auto;
}
</style>
