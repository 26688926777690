<template>
    <div class="bank-dashboard__info dashboard-section mb-9">
        <div
            class="d-flex justify-content-between align-items-start align-items-md-center mb-2 flex-column flex-md-row mx-3">
            <p class="text-gray-600 mb-2 mb-md-0">
                {{ $t("Info Center") }}
                <img src="/media/buying/icons/info_black.svg" alt="info_black">
            </p>
        </div>

        <div class="card">
            <div class="score-section">
                <div class="score-section__left">
                    <CircleChart
                        :labelTop="$t('Bank Score')"
                        :labelBottom="bankScore.titleBottom"
                        :chartData="bankScore"
                        :chartConfigs="{
                          width: 375,
                          height: 0.9 * 280,
                          valueVisible: false
                        }"
                        :values="[bankScore.score, bankScore.importance]"
                    />
                </div>
                <div class="score-section__right mt-20 mt-lg-0 your-banks-section">
                    <div class="d-flex justify-content-between align-items-start">
                        <div>
                            <h3 class="subtitle mb-4">{{ $t("Product Areas") }}</h3>
                            <!--              <a href="">{{ $t('View All') }}</a>-->
                        </div>
                        <div class="custom-tab-btn">
                            <div class="custom-tab-btn__label" :class="{'active': activeTab === 'top'}"
                                 @click="activeTab = 'top'">{{ $t("Top") }}
                            </div>
                            <div class="custom-tab-btn__label" :class="{'active': activeTab === 'low'}"
                                 @click="activeTab = 'low'">{{ $t("Low") }}
                            </div>
                        </div>
                    </div>

                    <CustomChartTabs
                        :activeTab="activeTab"
                        :tabData="tabData"
                        @onClickProductAreas="handleClickProductAreas"
                    />
                </div>
            </div>

            <div class="bank-dashboard__info--bottom">
                <div class="chart-info__customers d-flex align-items-center">
                    <img src="/media/buying/icons/office-building.svg" alt="office-building">
                    <div class="chart-info--label">{{ $t("Customers") }}</div>
                    <div class="chart-info--value">{{ customerCounts }}</div>
                    <!--          <div class="chart-info&#45;&#45;score green-type">-->
                    <!--            <span>0.0%</span>-->
                    <!--            <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                    <!--              <path d="M2.83159 0.890084C3.12846 0.290198 3.98401 0.290199 4.28087 0.890085L6.82975 6.04066C7.09568 6.57804 6.70469 7.20778 6.1051 7.20778H1.00736C0.407773 7.20778 0.0167809 6.57804 0.282718 6.04066L2.83159 0.890084Z"/>-->
                    <!--            </svg>-->
                    <!--          </div>-->
                </div>
                <div
                    class="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center w-100">
                    <div class="chart-info__respondents d-flex align-items-center">
                        <img src="/media/buying/icons/share-user.svg" alt="share-user">
                        <div class="chart-info--label">{{ $t("Respondents") }}</div>
                        <div class="chart-info--value">{{ respondentsCount }}</div>
                        <!--            <div class="chart-info&#45;&#45;score red-type">-->
                        <!--              <span>0.0%</span>-->
                        <!--              <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                        <!--                <path d="M2.83159 0.890084C3.12846 0.290198 3.98401 0.290199 4.28087 0.890085L6.82975 6.04066C7.09568 6.57804 6.70469 7.20778 6.1051 7.20778H1.00736C0.407773 7.20778 0.0167809 6.57804 0.282718 6.04066L2.83159 0.890084Z"/>-->
                        <!--              </svg>-->
                        <!--            </div>-->
                    </div>

<!--                    <div class="contact-buyingTeams mt-5 mt-sm-0 ">-->
<!--                        <a :href="`mailto:${platformConfigs.platformEmails.ContactEmail}`">-->
<!--                            {{ $t("Contact PLATFORMNAME", {platformName: platformConfigs.platformName}) }}-->
<!--                        </a>-->
<!--                        {{ $t("to add more Product Areas") }}-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CircleChartModel from "@/store/models/CircleChartModel";
import CustomChartTabs from "@/buying-teams/pages/bank/dashboard/components/CustomChartTabs.vue";
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";
import ScoreImportanceInfo from "@/buying-teams/shared-components/utils/ScoreImportanceInfo";
import store from "@/store";

export default {
    name: "InfoSection",
    components: {
        ScoreImportanceInfo,
        CircleChart,
        CustomChartTabs
    },
    props: {
        bankScore: {
            type: CircleChartModel
        },
        infoChartData: {
            type: Object,
            default: {}
        },
        customerCounts: {
            type: String,
            default: "0"
        },
        respondentsCount: {
            type: String,
            default: "0"
        }
    },
    data() {
        return {
            activeTab: "top"
        };
    },
    computed: {
        platformConfigs() {
            return store.getters.platformConfigs;
        },
        tabData() {
            let data = [];
            for (const [key, item] of Object.entries(this.infoChartData)) {
                data.push({
                    tabName: key,
                    data: item
                });
            }
            return data;
        }
    },
    methods: {
        handleClickProductAreas(item) {
            this.$emit('onClickProductAreas', item);
        }
    }
};
</script>

<style lang="scss">
.your-banks-section {
    padding-left: 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.bank-dashboard {
    &__info {
        .score-section {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &__right {
                width: calc(100% - 380px);

                .el-tabs__content {
                    margin-top: 20px;
                }

                .el-tab-pane {
                    .subtitle {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 19px;
                        text-align: center;
                        color: #515151;
                    }
                }
            }

            &__left {
                width: 380px;

                .subtitle {
                    text-align: left;
                }
            }
        }

        .card {
            padding-bottom: 20px;
        }

        &--bottom {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            margin-top: 40px;
            padding: 20px 10px 0 10px;
            display: flex;

            .contact-buyingTeams {
                background: rgba(167, 167, 167, 0.05);
                border-radius: 32px;
                padding: 10px 20px;
                color: rgba(172, 172, 172, 1);
                text-align: center;

                a {
                    margin: 0;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 13px;
                    text-align: center;
                    color: #435BF4;
                }
            }

            .chart-info {
                &__respondents {
                    padding-left: 17px;
                }

                &__customers {
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    width: max-content;
                    padding: 13px 0;
                    padding-right: 25px;
                }

                &--label {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 13px;
                    color: #9CA0AB;
                    margin-left: 14px;
                }

                &--value {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 13px;
                    color: rgba(0, 0, 0, 0.87);
                    margin-left: 14px;
                }

                &--score {
                    margin-left: 8px;
                    display: flex;
                    align-items: center;

                    span {
                        font-weight: 500;
                        font-size: 11.3193px;
                        line-height: 14px;
                        margin-right: 4px;
                    }

                    &.green-type {
                        span {
                            color: #1B9F72;
                        }

                        svg {
                            fill: #2BC490;
                        }
                    }

                    &.red-type {
                        span {
                            color: #E22D21FF;
                        }

                        svg {
                            fill: #E22D21;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .contact-buyingTeams {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 1200px) {
    .bank-dashboard__info .score-section {
        &__left {
            width: 100%;
        }

        &__right {
            width: 100%;
            border-left: none;
        }
    }
}

@media (max-width: 1160px) {
    .bank-dashboard {
        &__info {
            &--bottom {
                flex-direction: column;

                .chart-info {
                    &__customers {
                        border-right: none;
                    }

                    &__respondents {
                        padding-left: 0;
                    }
                }

                .contact-buyingTeams {
                    display: inline;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .product-areas-item .product-areas-item__left {
        overflow: hidden;

        .ring {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
