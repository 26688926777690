import { BankCustomerModel } from "@/store/models/bank/BankCustomerModel";

export class BankOverviewCustomerContainer {
  customersList:  BankOverviewCustomerContainerItem[];

  constructor(obj) {
    this.customersList = obj['customers'].map(cms => new BankOverviewCustomerContainerItem({name: cms.business_name, ...cms}));
  }

  async sortCustomersBy(what: string | null): Promise<BankOverviewCustomerContainerItem[]> {
    switch (what) {
      case 'highest_score':
        this.customersList = this.customersList.sort((q1: BankOverviewCustomerContainerItem, q2: BankOverviewCustomerContainerItem) => parseFloat(q2.score) - parseFloat(q1.score));
        break;
      case 'lowest_score':
        this.customersList = this.customersList.sort((q1: BankOverviewCustomerContainerItem, q2: BankOverviewCustomerContainerItem) => parseFloat(q1.score) - parseFloat(q2.score));
        break;
      case 'highest_importance_score':
        this.customersList = this.customersList.sort((q1: BankOverviewCustomerContainerItem, q2: BankOverviewCustomerContainerItem) => parseFloat(q2.importance) - parseFloat(q1.importance));
        break;
      case 'lowest_importance_score':
        this.customersList = this.customersList.sort((q1: BankOverviewCustomerContainerItem, q2: BankOverviewCustomerContainerItem) => parseFloat(q1.importance) - parseFloat(q2.importance));
        break;
      case 'highest_negative_gap':
        this.customersList = this.customersList.sort((q1: BankOverviewCustomerContainerItem, q2: BankOverviewCustomerContainerItem) => parseFloat(q1.gap) - parseFloat(q2.gap));
        break;
      case 'highest_positive_gap':
        this.customersList = this.customersList.sort((q1: BankOverviewCustomerContainerItem, q2: BankOverviewCustomerContainerItem) => parseFloat(q2.gap) - parseFloat(q1.gap));
        break;
      case 'highest_standard_deviation':
        this.customersList = this.customersList.sort((q1: BankOverviewCustomerContainerItem, q2: BankOverviewCustomerContainerItem) => parseFloat(q1.score) - parseFloat(q2.score));
        break;
      case 'lowest_standard_deviation':
        this.customersList = this.customersList.sort((q1: BankOverviewCustomerContainerItem, q2: BankOverviewCustomerContainerItem) => parseFloat(q1.score) - parseFloat(q2.score));
        break;
    }
    return this.customersList;
  }
}

class BankOverviewCustomerContainerItem extends BankCustomerModel{
  name: string;
  score: string;
  importance: string;
  gap: string;

  constructor(obj: any) {
    super(obj);

    this.name = obj['business_name'];
    this.score = obj['score'];
    this.importance = obj['importance'];
    this.gap =  (parseFloat(this.score) - parseFloat(this.importance)).toString();
  }
}
