<template>
    <div class="bank-dashboard platform-dashboard-pages">
        <BankOverviewFilter
            v-if="!filterLoading"
            @apply-filters="applyFilters"
        />

        <CustomLoader height="500px" v-if="loading"/>

        <template v-else>
            <InfoSection
                :bankScore="bankDashboardData.productAreasTotalStats"
                :infoChartData="infoSectionTabData"
                :customerCounts="bankDashboardData.customers_count"
                :respondentsCount="bankDashboardData.respondents_count"
                @onClickProductAreas="handleClickProductAreas"
            />
            <CompareProductAreas
                :showHeader="true"
                :showFiltered="true"
                :productAreas="bankProductAreas"
                @onClickProductAreas="handleClickProductAreas"
            />
            <div class="row custom-row">
                <div class="col-lg-5">
                    <CustomerScores
                        :customersContainer="customersContainer"
                    />

                </div>
                <div class="col-lg-7">
                    <div class="row custom-row">
                        <div class="col-12">
                            <RequestsSection :requests="dashboardRequests" :viewScreen="'bankOverview'"/>
                        </div>
                        <div class="col-12">
                            <IdeasSection :ideas="dashboardIdeas" :viewScreen="'bankOverview'"/>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import CircleChartModel from "@/store/models/CircleChartModel";
import InfoSection from "@/buying-teams/pages/bank/dashboard/sections/InfoSection.vue";
import CompareProductAreas from "@/buying-teams/pages/bank/dashboard/sections/CompareProductAreas";
import CustomerScores from "@/buying-teams/pages/bank/dashboard/sections/CustomerScores";
import RequestsSection from "@/buying-teams/pages/bank/dashboard/sections/RequestsSection";
import IdeasSection from "@/buying-teams/pages/bank/dashboard/sections/IdeasSection";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import {BankOverviewCustomerContainer} from "@/store/models/bank/BankOverviewCustomerContainer";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import BankOverviewFilter from "@/buying-teams/pages/bank/dashboard/components/BankOverviewFilter";

export default {
    name: "BankOverview",
    components: {
        CustomLoader,
        IdeasSection,
        RequestsSection,
        CustomerScores,
        CompareProductAreas,
        InfoSection,
        BankOverviewFilter
    },
    data() {
        return {
            loading: true,
            filterLoading: true,
        };
    },
    async mounted() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });

        if (this.isFilterLoaded) {
            await store.dispatch('initFiltersData', {type: BankFiltersTypeEnum.DASHBOARD})
        }

        this.filterLoading = false;
        this.loading = true;
        store.dispatch('fetchBankOverviewDashboardData', this.bankOverviewDashboardFilter)
            .finally(() => {
                this.loading = false;
                store.commit('SAVE_FILTER_LOADED', true);
            })
    },
    computed: {
        isFilterLoaded() {
            return store.getters.getIsFilterLoaded
        },
        bank() {
            return store.getters.bank;
        },
        bankProductAreas() {
            return store.getters.getBankProductAreas;
        },
        bankDashboardData() {
            return store.getters.bankDashboardData;
        },
        userInfo() {
            return store.getters.currentUser;
        },
        bankScore() {
            return new CircleChartModel(this.bankDashboardData.totalStats);
        },
        infoSectionTabData() {
            return {
                top: this.bankDashboardData.productAreasStatsTop,
                low: this.bankDashboardData.productAreasStatsLow
            };
        },
        timePeriods() {
            return store.getters.timePeriods;
        },
        tiers() {
            return store.getters.tierList
        },
        dashboardRequests() {
            return this.bankDashboardData.dashboardRequests ?
                this.bankDashboardData.dashboardRequests :
                {
                    critical: [],
                    high: [],
                    low: [],
                    normal: []
                };
        },
        dashboardIdeas() {
            return this.bankDashboardData.dashboardIdeas
        },
        bankOverviewDashboardFilter() {
            return store.getters.bankOverviewDashboardFilter
        },
        customersContainer() {
            let customersDataList = this.bankDashboardData.customer_stats || [];
            return new BankOverviewCustomerContainer({customers: customersDataList});
        }
    },
    methods: {
        applyFilters(filters) {
            this.loading = true;
            store.dispatch('fetchBankOverviewDashboardData', filters)
                .finally(() => {
                    this.loading = false
                })
        },
        async handleClickProductAreas(title) {
            const mainFilters = { ...this.bankOverviewDashboardFilter };
            store.commit("SET_SAVED_FILTERS_PREV_PAGE", {
                page: BankFiltersTypeEnum.PRODUCT_AREA_OVERVIEW,
                filters: { ...mainFilters }
            });
            this.$router.push(`/bank/product-areas/${title}`)
        }
    }
};
</script>

<style lang="scss">
.bank-dashboard {
    .card {
        background: #FFFFFF;
        box-shadow: 0 9px 15px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
    }

    .custom-row {
        --bs-gutter-x: 2rem;
    }
}

.dashboard-section {
    &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(67, 67, 67, 0.6);
        padding-left: 9px;
        margin-bottom: 9px;

        &--count {
            margin-left: 8px;
            background: #FFFFFF;
            border-radius: 3px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #000000;
            padding: 2px 4px;
        }
    }

    .custom-tab-btn {
        display: flex;

        &__label {
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #8B8B8B;
            padding: 0 21px 8px 21px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.03);

            &:hover {
                color: #435BF4;
            }

            &.active {
                border-color: rgba(67, 91, 244, 1);
                color: rgba(67, 91, 244, 1);
            }
        }
    }
}
</style>
